import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="header">
      <div className="storeName-Logo">
       <div className="logo">
          <img src="/logo192.png" alt="لوگو فروشگاه" />
        </div>
        <h1>فروشگاه اینترنتی رویا</h1>
      </div>
      

      <nav className="homeProducts">
        <ul>
          <li><Link to="/">محصولات</Link></li>
          <li><Link to="/contact">تماس با ما</Link></li>
        </ul>
      </nav>
    </header>

  );
}

export default Header;
