import React from 'react';
import ProductList from '../components/ProductList';

function Products() {
  return (
    <div>
      <h2>محصولات</h2>
      <ProductList />
    </div>
  );
}

export default Products;
